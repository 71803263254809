.dark {
  .text-field-wrapper {
    label {
      color: $white;
    }
    input {
      border-color: $dark-gray;
      background-color: $dark3 !important;
      color: white;
      &:focus,
      &:hover {
        border-color: $primary;
      }
    }
    .ant-picker{
      background-color: $dark3;
      border-color:#3a3c3e;
    }
  }
  input{
    border-color: $dark-gray;
      background-color: $dark3 !important;
      color: white;
      &:focus,
      &:hover {
        border-color: $primary;
      }
  }
  .ant-select-selection-search{
    .ant-select-selection-search-input{
      width: 0;
    }
  }
  .ant-input-affix-wrapper{
    background-color: $dark3;
    border-color: #3a3c3e;
    .ant-input-suffix{
      color: white;
    }
  }
  .trackings-head .driver-info-top{
    color: $white;
    span{
      color: $white;
    }
  }
}

.text-field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    @extend %label;
  }
  input,
  .ant-input-affix-wrapper {
    @extend %input;
  }
  .ant-input-affix-wrapper {
    &-focused {
      border-color: $primary;
    }
    input {
      height: 38px;
    }
    .ant-input-suffix {
      opacity: 0.5;
    }
  }
  &.error {
    label {
      color: $red;
    }
  }
}

.text-field-suffix {
  position: relative;
  &.unicon {
    .icomoon-icon {
      display: none;
    }
    input {
      padding-left: 16px;
    }
  }
  .icomoon-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: $gray3;
    // transition: $theme;
    z-index: 1;
  }
  input {
    padding-left: 40px;
    padding-right: 40px;
    // transition: $theme;
  }
  .anticon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: $gray3;
    cursor: pointer;
    opacity: 0.8;
  }
}
