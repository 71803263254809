.dark {
     .app-sidebar {
          background-color: $dark2;
          .toggle-menu {
               .icon-wrapper {
                    border-color: $gray1;
               }
          }
     }
}

.app-sidebar {
     background-color: $black;
     transition: $theme;
     height: 100vh;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     .logo {
          height: 28px;
          position: relative;
          display: flex;
          justify-content: center;
          margin: 24px 0;
          .logo-title {
               opacity: 1;
               transition: all 0.2s;
               margin: 5px;
          }
          &.collapsed {
               .logo-title {
                    opacity: 0;
                    display: none;
               }
          }
          &:hover {
               cursor: pointer;
          }
     }
     .toggle-menu {
          height: 70px;
          // border-top: 1px solid $border;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 12px;
          cursor: pointer;
          transition: all 0.2s;
          &.collapsed {
               padding-right: 28px !important;
               .icon-wrapper {
                    transform: rotateY(180deg);
               }
          }
          .icon-wrapper {
               border-radius: 50%;
               border: 1px solid $dark2;
               height: 32px;
               width: 32px;
               display: flex;
               align-items: center;
               justify-content: center;
               transition: $theme;
               svg {
                    fill: $gray4;
               }
          }
     }
     .sidebar-scroll {
          height: calc(100vh - 166px);
          overflow-y: auto;
          &::-webkit-scrollbar {
               display: none;
          }
          .ant-menu {
               background-color: transparent;
               border-right: none;
               &-item-group {
                    &:not(:last-child) {
                         margin-bottom: 16px;
                    }
                    &-title {
                         font-family: Roboto;
                         font-size: 12px;
                         font-weight: 400;
                         line-height: 16px;
                         letter-spacing: 0.5px;
                         color: $gray3;
                         text-transform: uppercase;
                         padding: 4px 24px;
                         margin-bottom: 8px;
                    }
                    &-list {
                         .ant-menu-item {
                              transition: padding 0s;
                              color: $gray4;
                              background-color: transparent;
                              .ant-menu-title-content a {
                                   color: inherit;
                                   @extend %regular-14;
                                   transition: none !important;
                              }
                              svg {
                                   height: 20px;
                                   width: 20px;
                                   fill: $gray4;
                              }
                              span {
                                   color: inherit;
                              }
                         }
                         .ant-menu-item-selected {
                              color: $primary;
                              background-color: rgba($color: $primary, $alpha: 0.1);
                              .ant-menu-title-content {
                                   color: inherit;
                              }
                              svg {
                                   fill: $primary;
                              }
                         }
                    }
               }
          }
          .ant-menu-inline-collapsed {
               .ant-menu-item-group-title {
                    display: none;
               }
               .ant-menu-item {
                    padding-left: 24px !important;
                    padding-top: 4px !important;
                    &-icon {
                         transform: translate(3px, -6px);
                    }
               }
               .ant-menu-item-selected::after {
                    transform: scaleY(1) !important;
                    opacity: 1 !important;
               }
          }
     }
}
