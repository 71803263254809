.dark {
    .admin-layout {
        background-color: $dark1;
        .header {
            background-color: $dark2;
        }
        .routes {
            background-color: $dark2;
        }
    }
}
.admin-layout {
    background: $gray7;
    height: 100%;
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        background: $white;
        padding: 0 24px;
        .tabs-wrapper {
            margin-top: 19px;
            .ant-tabs {
                .ant-tabs-nav {
                    margin-bottom: 0 !important;
                    .ant-tabs-nav-list {
                        .ant-tabs-tab {
                            .ant-tabs-tab-btn {
                                a {
                                    padding-bottom: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .routes {
        background: $white;
        padding: 20px;
        height: calc(100vh - 80px);
        position: relative;
        overflow-y: auto;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
        }
    }
}
