.users {
     &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .right {
               display: flex;
               align-items: center;
          }
     }
}
