.dark {
     .pages {
          background-color: $dark1;
          .page {
               background-color: $dark2;
          }
     }
}

.pages {
     height: calc(100vh - 72px);
     padding: 20px;
     position: relative;
     transition: $theme;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: #f0f3f8;
     .page {
          min-height: 50vh;
          background-color: white;
          border-radius: 8px;
          padding: 24px;
          transition: $theme;
     }
}
