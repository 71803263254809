// Margin
.mr-40 {
     margin-right: 40px;
}
.ml-40 {
     margin-left: 40px;
}
.mt-40 {
     margin-top: 40px;
}
.mb-40 {
     margin-bottom: 40px;
}
.mr-32 {
     margin-right: 32px;
}
.ml-32 {
     margin-left: 32px;
}
.mt-32 {
     margin-top: 32px;
}
.mb-32 {
     margin-bottom: 32px;
}
.mr-24 {
     margin-right: 24px;
}
.ml-24 {
     margin-left: 24px;
}
.mt-24 {
     margin-top: 24px;
}
.mb-24 {
     margin-bottom: 24px;
}
.mr-16 {
     margin-right: 16px;
}
.ml-16 {
     margin-left: 16px;
}
.mt-16 {
     margin-top: 16px;
}
.mb-16 {
     margin-bottom: 16px;
}
.mr-8 {
     margin-right: 8px;
}
.ml-8 {
     margin-left: 8px;
}
.mt-8 {
     margin-top: 8px;
}
.mb-8 {
     margin-bottom: 8px;
}
.mr-4 {
     margin-right: 4px;
}
.ml-4 {
     margin-left: 4px;
}
.mt-4 {
     margin-top: 4px;
}
.mb-4 {
     margin-bottom: 4px;
}

.mx-auto {
     margin: 0 auto !important;
}

// Border radius
.border-8 {
     border-radius: 8px;
}
.border-4 {
     border-radius: 4px;
}

// Text
.text-center {
     text-align: center;
}

.uppercase {
     text-transform: uppercase;
}
.capitalize {
     text-transform: capitalize;
}

// Width
.w-100 {
     width: 100%;
}

.mw-250 {
     max-width: 250px;
     width: 100%;
}

.w-150 {
     width: 150px;
}
.w-200 {
     width: 200px;
}

// Postion
.relative {
     position: relative;
}

.green {
     color: $green;
}

// Flex
.d-flex {
     display: flex;
}

.align-center {
     align-items: center;
}

.justify-center {
     justify-content: center;
}
.justify-space-between {
     justify-content: space-between;
}
.justify-end {
     justify-content: flex-end;
}

.ant-picker {
     border-radius: 24px !important;
}
