.accordion {
     &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .buttons {
               display: flex;
               align-items: center;
               gap: 16px;
          }
     }
}
