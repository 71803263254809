.dark {
     .ant-btn {
          &-default {
               color: $gray3;
               border-color: $dark-gray;

               &:hover {
                    border-color: $primary;
                    color: $primary;
               }
          }
          &:disabled {
               background-color: $dark3;
               border-color: $gray1;
               color: $gray1;
          }
     }
}

.ant-btn {
     @extend %medium-14;
     height: 40px;
     border-radius: 20px;
     box-shadow: none;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 10px 26px;
     text-shadow: none;
     span {
          color: inherit;
     }
     .icomoon-icon {
          margin-right: 4px;
     }
     &-default {
          color: $gray1;
          border-color: $gray5;
          background-color: transparent !important;
          transition: $theme;
     }
     &.outlined {
          color: $primary;
          border-color: $primary;
     }
     &.medium {
          height: 36px;
     }
     &.rounded {
          height: 36px;
          width: 36px;
          padding: 0;
          svg {
               margin: 0;
               height: 20px;
               width: 20px;
          }
     }
}

.doble-button {
     display: flex;
     position: relative;
     width: fit-content;
     .ant-btn {
          &:first-child {
               border-bottom-right-radius: 0;
               border-top-right-radius: 0;
               border-right: none;
               padding-right: 8px;
               padding-left: 14px;
          }
          &:last-child {
               border-bottom-left-radius: 0;
               border-top-left-radius: 0;
               border-left: none;
               padding-left: 8px;
               padding-right: 14px;
          }
     }
     &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 0.5px;
          height: 70%;
          background-color: $gray4;
     }
     &.medium {
          .ant-btn {
               padding-top: 4px;
               padding-bottom: 4px;
               height: 36px;
          }
     }
}
