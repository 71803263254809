.trackings {
     &-head {
          display: flex;
          justify-content: space-between;
          .driver-info {
               &-top {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    .circle {
                         display: flex;
                         height: 16px;
                         width: 16px;
                         background-color: $green;
                         border-radius: 50%;
                         margin-right: 12px;
                    }
                    .name {
                         @extend %medium-16;
                         color: $black;
                         margin-right: 16px;
                    }
                    .status {
                         padding: 4px 16px;
                         border-radius: 24px;
                         border: 1px solid $gray3;
                         @extend %medium-12;
                    }
               }
               &-desc {
                    margin-bottom: 4px;
                    @extend %regular-14;
                    color: $gray2;
               }
          }
          .driver-btn {
               display: flex;
               align-items: center;
               gap: 16px;
          }
     }
     .map-container {
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          background-color: $primary-light;
          margin-top: 24px;
          // box-shadow: 0px 0px 5px rgba(33, 33, 52, 0.15);
          iframe + div {
               border: none !important;
          }
     }
}
