.dark {
  .password-field-wrapper {
    label {
      color: $white;
    }
    .ant-input-password {
      border-color: $dark-gray;
      background-color: $dark3 !important;
      &:hover {
        border-color: $primary;
      }
      input {
        color: white;
      }
    }
  }
}

.password-field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    @extend %label;
  }
  .ant-input-password {
    @extend %input;
    padding: 0;
    overflow: hidden;
    position: relative;
    input {
      padding: 0 18px;
      color: $black;
      @extend %regular-14;
      background-color: transparent;
    }
    .ant-input-suffix {
      position: absolute;
      height: 100%;
      right: 16px;
      .eye-icon {
        height: 18px;
        width: 18px;
        fill: $gray3;
        cursor: pointer;
      }
    }
    &.ant-input-affix-wrapper-focused {
      border-color: $primary;
    }
  }
  &.error {
    label {
      color: $red;
    }
  }
}
