.dark {
     .app-nav {
          box-shadow: 0px 1px 14px rgba(33, 33, 52, 0.05);
          background-color: $dark2;
          .main {
               .time_drop {
                    border: 1px solid rgba(58, 60, 62, 1);
               }
               .left {
               }
               .right {
                    .nav-info {
                         span {
                              &:first-child {
                                   color: $white;
                              }
                              &:last-child {
                                   color: $gray4;
                              }
                         }
                    }
               }
          }
     }
}

.app-nav {
     display: flex;
     align-items: center;
     justify-content: space-between;
     z-index: 11;
     height: 72px;
     box-shadow: 0px 1px 14px rgba(33, 33, 52, 0.05);
     background-color: white;
     transition: $theme;
     .main {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 24px;
          width: 100%;
          .left {
               display: flex;
               align-items: center;
          }
          .time_drop {
               transform: scale(1.2);
               height: 40px;
               border-radius: 20px;
               border: 1px solid $gray5;
               display: flex;
               align-items: center;
               justify-content: space-around;
               padding-right: 32px;
               padding-left: 16px;
               transition: $theme;
               .item {
                    display: flex;
                    align-items: center;
                    &:not(:last-child) {
                         margin-right: 16px;
                    }

                    .left {
                         width: 24px;
                         height: 24px;
                         border-radius: 4px;
                         background: rgba(102, 183, 241, 0.1);
                         display: flex;
                         align-items: center;
                         justify-content: center;
                    }
                    .right {
                         text-transform: uppercase;
                         margin-left: 8px;
                    }
                    .bg_blue {
                         background: rgba(102, 183, 241, 0.1);
                    }
                    .bg_green {
                         background: rgba(92, 177, 118, 0.1);
                    }
                    .bg_gray {
                         background: rgba(152, 152, 152, 0.1);
                    }
                    .bg_yellow {
                         background: rgba(242, 157, 65, 0.1);
                    }
               }
               .blue {
                    color: $blue;
               }
               .green {
                    color: $green;
               }
               .gray {
                    color: $gray3;
               }
               .yellow {
                    color: $primary;
               }
          }
          .right {
               display: flex;
               align-items: center;
               gap: 24px;
               .nav-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    cursor: pointer;
                    user-select: none;
                    span {
                         transition: $theme;
                         &:first-child {
                              @extend %medium-14;
                              color: $black;
                         }
                         &:last-child {
                              @extend %regular-12;
                              color: $gray2;
                         }
                    }
               }
          }

          .ant-switch {
               width: 40px;
               height: 24px;
               border-radius: 24px;
               background-color: $gray5;
               &:focus {
                    box-shadow: none;
               }
               .ant-switch-handle {
                    width: 16px;
                    height: 16px;
                    top: 4px;
                    left: 4px;
               }
               &-checked {
                    background-color: $primary;
                    .ant-switch-handle {
                         left: calc(100% - 18px - 2px);
                    }
               }
          }
     }
}

.navbar-menu-dropdown {
     padding: 4px 0;
     display: flex;
     flex-direction: column;
     width: 298px;
     border-radius: 20px;
     .ant-dropdown-menu-item {
          all: unset;
          .ant-dropdown-menu-title-content {
               display: inline-block;
               width: 100%;
          }
     }
     .item {
          display: flex;
          flex-direction: column;
          span {
               display: inline-block;
               width: 100%;
          }
          &:not(:last-child) {
               border-bottom: 1px solid $gray6;
          }
     }
     .menu-links {
          a {
               display: flex;
               align-items: center;
               gap: 8px;
               color: $gray1;
               padding: 8px 16px;
               @extend %regular-14;
               &::after {
                    display: none;
               }
               &.active {
                    color: $primary;
               }
          }
     }
     .menu-companies {
          &.all-companies {
               height: 400px;
               overflow: auto;
          }
          &-title {
               padding: 0 4px;
               text-align: center;
               @extend %bold-14;
               color: $black;
               padding: 8px 16px;
          }
          &-link {
               @extend %regular-14;
               color: $black;
               padding: 8px 16px;
               text-align: center;
               cursor: pointer;
               border-radius: 50px;
               &.active {
                    color: white;
                    background-color: $primary;
               }
          }
          &-add {
               color: #66b7f1;
               @extend %regular-14;
               cursor: pointer;
               text-align: center;
               padding: 4px 16px;
          }
          &-user {
               display: flex !important;
               align-items: center;
               justify-content: center;
               gap: 16px;
               padding: 8px 16px;
               cursor: pointer;
               span {
                    @extend %regular-14;
                    width: fit-content;
                    color: $black;
                    text-decoration: underline;
               }
               svg {
                    color: $red;
                    height: 20px;
                    width: 20px;
               }
          }
     }
     .logout {
          display: flex;
          align-items: center;
          flex-direction: initial;
          padding: 8px 16px;
          gap: 8px;
          color: $gray1;
          cursor: pointer;
     }
}
