@mixin scrollbar($width: 5px, $height: 8px, $thumb: $gray1, $track: $gray5) {
     &::-webkit-scrollbar {
          height: $height;
          width: $width;
     }

     &::-webkit-scrollbar-track {
          background-color: $track;
          border-radius: 5px;
     }

     &::-webkit-scrollbar-thumb {
          background-color: $thumb;
          border-radius: 5px;
          &:active {
               cursor: pointer;
               background-color: rgba($color: $thumb, $alpha: 0.8);
          }
     }
     &::-webkit-scrollbar-corner {
          background-color: transparent;
     }
}
