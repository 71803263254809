body.dark{
     .tableDiv{
          .line{
               border-color: $white;
          }
          .vehicle-unit{
               color: $white
          }
          .table{
               th, td{
                    border-color: $white;
               }
          }

     }
}

.ifta-reports {
     &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .right {
               display: flex;
               align-items: center;
          }
     }
     
     .tableDiv{
          width: 880px;
          padding: 20px;
          padding-top: 10px;
          margin-bottom: 10px;
          padding-bottom: 0;
          .table{
               border-collapse: collapse;
               width: 100%;
               margin-bottom: 50px;
               th, td{
                    border: 1px solid $black;
                    padding: 5px;
                    font-size: 20px;
               }
               td{
                    padding: 0 10px;
               }
               th:nth-child(1) {
                    width: 5%;
                  }
                  th:nth-child(2) {
                    width: 65%;
                  }
                  th:nth-child(3) {
                    width: 35%;
                  }
          }
          .line{
               border-bottom: 2px solid $black;
               width: 100%;
          }
          .vehicle-unit{
               margin-top: 16px;
               font-size: 20px;
               text-align: center;
               font-weight: 600;
               color: $black;
          }
     }
     
}


