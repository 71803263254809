.dark {
  .app-login {
    background-color: $dark1;
    form {
      background-color: $dark2;
      border: none;
    }
    
  }
}
.app-login {
  width: 100%;
  height: 100%;
  background: #f0f3f8;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid $gray7;
    width: 552px;
    border-radius: 4px;
    padding: 56px 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    box-shadow: 0px 1px 4px rgba(33, 33, 52, 0.1);

    img {
      // height: 32px;
      width: 106px;
      margin-bottom: 60px;
    }

    .input-wrapper:first-of-type {
      margin-bottom: 24px;
    }
  }
}

.custom-password-input .ant-input-password-icon {
  display: none;
}
