//Main colors
$primary: #f0324c; //#fb0
// $primary: #fb0; //#fb0
$primary-light: #fff8e5;
// $red: #fb0;
$red: #f0324c;
$red-light: #fdefee;
$green: #5cb176;
$green-light: #eff7f1;
$blue: #66b7f1;
$blue-light: #f0f8fe;

// $dark-red: #fb0;
$dark-red: #f0324c;

//Text colors
$black: #0b0b0b;
$gray1: #686868;
$gray2: #787878;
$gray3: #989898;
$gray4: #d0cfcf;
$gray5: #e2e2e2;
$gray6: #ebebeb;
$gray7: #f5f5f5;
$white: #ffffff;

$dark1: #16151e;
$dark2: #252531;
$dark3: #2a2a38;

$dark-gray: #3a3c3e;
