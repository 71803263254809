* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black;
    transition: $theme;
    margin: 0;
  }
}

body.dark {
  background-color: $dark1;
  .ant-layout {
    background-color: $dark1;
  }
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6, table, .current-status {
    color: $white;
  }
  a{
    color: $dark2
  }

}

.app {
  position: relative;
  height: 100vh;
}

.ant-layout-content {
  margin: 0 !important;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
}
.ant-table-body {
  overflow: auto scroll;
  max-height: 1700px !important;
}
