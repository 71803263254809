.dark {
     .ant-picker-range {
          background-color: transparent !important;
     }
     .date-picker-wrapper {
          .ant-picker {
               border-color: $dark-gray;
               background-color: transparent !important;
               &-input {
                    input {
                         background-color: $dark3 !important;
                         color: white;
                    }
               }
               &-clear {
                    background-color: $dark3;
               }
               &-focused,
               &:hover {
                    border-color: $primary;
               }
          }
     }
}

.date-picker-wrapper {
     width: 100%;
     .ant-picker {
          padding: 0;
          border-radius: 20px;
          width: 100%;
          &-range {
               padding-right: 24px;
               overflow: hidden;
               .ant-picker-suffix,
               .ant-picker-clear {
                    right: 16px;
               }
          }
          &-input {
               padding: 0;
               position: relative;
               input {
                    @extend %input;
                    height: 38px;
               }
          }
          &-suffix {
               margin: 0 !important;
               transform: translateY(-50%);
               right: 24px;
               color: $gray3;
               position: absolute;
               top: 50%;
               svg {
                    height: 16px !important;
                    width: 16px !important;
               }
          }
          &-clear {
               color: $gray3;
               right: 24px;
               font-size: 16px;
               top: 50%;
          }
     }
}

.ant-picker-range {
     // background-color: $dark3;
     // border-color: $dark-gray;
     width: 100%;
     height: 38px;
     padding: 0 16px;

     input {
          // color: white;
          height: 100%;
     }
     // .ant-picker-suffix,
     // .anticon-swap-right,
     // anticon-close-circle
     .anticon {
          color: $gray3;
     }
}

.ant-picker-dropdown {
     .ant-picker-panel-container {
          border-radius: 20px;
          box-shadow: 0px 2px 15px rgba(33, 33, 52, 0.1);
          .ant-picker-panel {
               .ant-picker-header {
                    &-prev-btn {
                         transform: translateX(-10px);
                         color: $black;
                    }
                    &-next-btn {
                         transform: translateX(10px);
                         color: $black;
                    }
               }
               .ant-picker-content {
                    thead {
                         tr th {
                              font-weight: 500;
                         }
                    }
               }
               .ant-picker-body {
                    padding-bottom: 16px;
               }
          }
     }
}
