.dark {
  .phone-field-wrapper {
    label {
      color: $white;
    }
    input {
      border-color: $dark-gray;
      color: white;
      &:focus,
      &:hover {
        border-color: $primary;
      }
    }
  }
}

.phone-field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    @extend %label;
  }
  input {
    @extend %input;
    border-width: 1px;
    border-style: solid;
    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0 2px rgba($color: $primary, $alpha: 0.2);
      border-right-width: 1px;
      outline: 0;
    }
    &:hover {
      border-color: $primary;
    }
  }
  &.error {
    label {
      color: $red;
    }
  }
}
